<template>
  <div class="date-ranger">
    <div class="detail">
      <label class="detail-label" for="from">From</label>
      <input
        class="detail-control"
        v-model="startDateModel"
        @input="$emit('update:startDate', startDateModel)"
        type="date"
        id="from"
        :max="endDateModel" />
    </div>

    <div class="detail">
      <label class="detail-label" for="to">To</label>
      <input
        class="detail-control"
        v-model="endDateModel"
        @input="$emit('update:endDate', endDateModel)"
        type="date"
        id="to"
        :min="startDateModel" />
    </div>

    <div class="detail">
      <button
        @click="
          saveDefaults(startDateModel, endDateModel);
          $emit('update', { startDate: startDateModel, endDate: endDateModel });
        ">
        Update
      </button>
    </div>
  </div>
</template>

<script>
  import { saveDefaults } from "@/helpers/dateTimes";

  export default {
    props: {
      startDate: {
        type: String,
      },
      endDate: {
        type: String,
      },
    },

    emits: ["update:endDate", "update:startDate", "update"],

    data() {
      return {
        startDateModel: this.startDate,
        endDateModel: this.endDate,
        saveDefaults,
      };
    },
  };
</script>

<style lang="scss">
  .date-ranger {
    width: 100%;
    @include flex-initial($gap: 1rem);
    flex-direction: row !important;

    .detail{
      @include flex-center;
    }

    .detail-label {
      width: auto;
      min-width: 40px;
      @media screen and (max-width: $vertical_tablet_width) {
        width: 40px;
      }
    }
    input.detail-control {
      line-height: $input_min_height;
      height: $input_min_height;
      border: 0;
    }
  }
</style>
